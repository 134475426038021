import { makeLogger, sleep } from "@gazebo/utils";

const logger = makeLogger("budb:pouch");

export type PouchError = { status: number };

export const isPouchError = (x: any): x is PouchError => {
    return !!x.status;
};

export const withPouchRetries = async <T>(
    f: () => T,
    count = 5,
    sleepTime = 100
): Promise<T> => {
    let retry = count;

    while (retry > 0) {
        try {
            return await f();
        } catch (error) {
            logger.inTestEnv(error + "");

            if (!isPouchError(error)) {
                logger.toInvestigateTomorrow(error + "");
                throw error;
            }

            if (error.status === 409) {
                logger.inTestEnv("conflict, retrying.");
            }

            await sleep(sleepTime);
            retry -= 1;

            if (retry === 0) {
                logger.wakeMeUpInTheMiddleOfTheNight(
                    "an error occured during the put:",
                    error
                );
                throw error;
            }
        }
    }

    logger.wakeMeUpInTheMiddleOfTheNight("This state should not happen");
    throw new Error("something wrong happened during doc update.");
};

