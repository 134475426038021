import { BuDB, Doc } from "@budb";
import { makeAutoObservable } from "mobx";
import { BuDBUpdate, IDocInitializer, IDocJSON, isDocJSON } from "./Doc";
import { Status } from "./status";

export class Collection<T> {
  public readonly budb: BuDB;
  public readonly type: string;
  public docs: Doc<T>[];
  public status: Status;
  public readonly initializer: IDocInitializer<T>;

  public constructor(
    budb: BuDB,
    type: string,
    initializer: IDocInitializer<T>
  ) {
    this.budb = budb;
    this.type = type;
    this.initializer = (x) => ({ ...initializer(x), type: this.type });
    this.docs = [];
    this.status = Status.NONE;

    this.status = Status.LOADING;
    this.__loadFromBuDB();

    makeAutoObservable(this);
  }

  public get(id: string): Doc<T> {
    return this.budb.document(id, this.initializer(id));
  }

  public async __loadFromBuDB(doc?: BuDBUpdate<T>) {
    if (doc && doc.type !== this.type) {
      // ignore
      return;
    }

    const found = await this.budb.db.find({
      selector: { type: this.type },
      sort: ["_id"],
    });

    if (found.warning) {
      console.warn(found.warning);
    }

    const newDocs: Doc<T>[] = [];

    found.docs.forEach((doc) => {
      if (!isDocJSON(doc)) {
        console.error("we have a problem with this doc", doc);
        return;
      }

      // TODO: pass the data we already have instead of doing another get.
      // TODO: validate the data format to be our expect T
      const verifiedDoc = doc as IDocJSON<T>;
      newDocs.push(this.budb.document<T>(doc._id, verifiedDoc));
    });

    this.docs = newDocs;
    this.status = Status.READY;
  }
}
