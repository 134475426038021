import { Doc, withUniqueOptimism } from "@budb";
import { IDocInitializer } from "@budb/Doc";
import { OptimisticDoc } from "@budb/OptimisticDoc";
import { baseParagraph } from "@c/GardenEditor/blocks";
import { IBlock, isInternalLink } from "@c/GardenEditor/types";
import { makeLogger } from "@gazebo/utils";
import { makeAutoObservable } from "mobx";
import { Descendant, Text } from "slate";
import { NotebookStore } from "./NotebookStore";
import { IBlockJSON, IBlockReference } from "./types";

const logger = makeLogger("store:note:BlockStore");

export const blockInitializer: IDocInitializer<IBlockJSON> = (id: string) => {
  return {
    subject: null,
    children: [baseParagraph()],
  };
};

export class BlockStore {
  doc: Doc<IBlockJSON>;
  notebook: NotebookStore;
  optimism: OptimisticDoc<IBlockJSON>;

  constructor(notebook: NotebookStore, doc: Doc<IBlockJSON>) {
    this.doc = doc;
    this.notebook = notebook;
    this.optimism = withUniqueOptimism(doc)

    makeAutoObservable(this);
  }

  public get id(): string {
    return this.doc._id;
  }

  public get slateContent(): IBlock {
    const r: IBlock = {
      type: "block",
      blockID: this.id,
      subject: this.optimism.data.subject || undefined,
      children: this.optimism.data.children,
      hasChanges: this.optimism.hasChanges
    };

    logger.inTestEnv('get slateContent:', r)
    return r
  }

  public set slateContent(data: IBlock) {
    logger.inTestEnv('set slateContent:', data)

    this.optimism.update(() => {
      return {
        children: data.children,
        subject: data.subject || null,
      }
    })
  }

  public get ref(): IBlockReference {
    return {
      type: "blockReference",
      blockID: this.id,
    };
  }

  public get references(): string[] {
    const r: string[] = [];
    let q: Descendant[] = [...this.doc.data.children];

    do {
      const current = q.pop();

      if (!current) {
        break;
      }

      if (isInternalLink(current)) {
        if (current.target) {
          r.push(current.target);
        }
      } else if (Text.isText(current)) {
        continue;
      } else {
        current.children.forEach((x) => q.push(x));
      }
    } while (q.length > 0);

    return r;
  }
}
