import { isClientSide } from "@gazebo/nextjs/utils";
import { blockInitializer } from "@store/BlockStore";
import { noteInitializer } from "@store/NoteStore";
import { IBlockJSON, INoteJSON } from "@store/types";
import keys from "lodash/keys";
import max from "lodash/max";
import { when } from "mobx";
import { BuDB } from "./BuDB";
import { Doc, IDocJSON } from "./Doc";
import { Status } from "./status";

export const isMoreRecent = (
  docA: Doc<any>,
  docB: {
    createdAt: null | number;
    updatedAt: null | number;
    deletedAt: null | number;
  }
): boolean => {
  const fieldA = max([docA.updatedAt, docA.deletedAt, docA.updatedAt]);
  const fieldB = max([docB.updatedAt, docB.deletedAt, docB.updatedAt]);
  return (fieldA || 0) > (fieldB || 0);
};

export const withLocalStorage = (buDB: BuDB) => {
  const notes = buDB.collection<INoteJSON>("notes", noteInitializer);
  const blocks = buDB.collection<IBlockJSON>("blocks", blockInitializer);

  const loadNote = async (key: string): Promise<void> => {
    const id = key.substring("notes:".length);

    const remote = notes.get(id);
    const local = JSON.parse(localStorage.getItem(key)!);

    await when(() => remote.status === Status.READY);

    const forceSync =
      !remote.data.content || remote.data.content.length < local.content.length;

    if (isMoreRecent(remote, local) && !forceSync) {
      return;
    }

    /**
     *   id: NoteID;
     *   path: NoteID[];
     *   title: string;
     *   content: NoteContent;
     *   createdAt: number;
     *   updatedAt: number | null;
     *   deletedAt: number | null;
     */
    remote.update((x: IDocJSON<INoteJSON>) => {
      const newX: INoteJSON = {
        path: local.path,
        title: local.title,
        content: local.content,
      };
      return newX;
    });
  };

  const loadBlock = async (key: string): Promise<void> => {
    const id = key.substring("blocks:".length);

    const remote = blocks.get(id);
    const local = JSON.parse(localStorage.getItem(key)!);

    /**
         * children: Array(1)
         *  0: {type: 'paragraph', children: Array(1)}
            length: 1
            [[Prototype]]: Array(0)
            createdAt: 1632646740139
            deletedAt: null
            id: "c9da2d28-433e-4998-a36a-b2c005a76077"
            subject: "c9da2d28-433e-4998-a36a-b2c005a76077"
            updatedAt: 1632646740139
         */
    await when(() => remote.status === Status.READY);

    const forceSync =
      !remote.data.children ||
      remote.data.children.length < local.children.length;

    if (isMoreRecent(remote, local) && !forceSync) {
      return;
    }

    remote.update((x: IDocJSON<IBlockJSON>) => {
      const newX: IBlockJSON = {
        children: local.children,
        subject: local.subject === local._id ? null : local.subject,
      };
      return newX;
    });
  };

  if (isClientSide()) {
    keys(localStorage).forEach((k, i) => {
      if (k.startsWith("notes:")) {
        loadNote(k);
      }
      if (k.startsWith("blocks:")) {
        loadBlock(k);
      }
    });
  }

  return buDB;
};
