import every from "lodash/every";

export interface ILegacyDoc {
  type: string;
  createdAt: number;
  updatedAt: number;
  deletedAt?: number;
}

export const isLegacyDocJSON = (doc: any): doc is ILegacyDoc => {
  const fields = ["type", "createdAt"];
  return every(fields.map((field) => !!doc[field]));
};

export const dateOrNull = (t?: number): Date | null => {
  if (!t) {
    return null;
  }
  return new Date(t);
};
