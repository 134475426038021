import { TimeStore } from "@gazebo/mobx";
import { strToU8, zip } from "fflate";
import { saveAs } from "file-saver";
import sortBy from "lodash/sortBy";
import { makeAutoObservable } from "mobx";
import { BuDB, Collection } from '../budb';
import { blockInitializer, BlockStore } from "./BlockStore";
import { FolderStore } from "./FolderStore";
import { noteInitializer, NoteStore } from "./NoteStore";
import { IBlockJSON, INoteJSON } from "./types";

let uniqsNote: { [key: string]: NoteStore } = {}
let uniqsBlocks: { [key: string]: BlockStore } = {}

export class NotebookStore {
    public readonly timeStore: TimeStore;
    public readonly budb: BuDB;
    public readonly notes: Collection<INoteJSON>;
    public readonly blocks: Collection<IBlockJSON>;
    public readonly folders: FolderStore;

    public constructor(timestore: TimeStore, buDB: BuDB) {
        this.timeStore = timestore;
        this.budb = buDB
        this.notes = this.budb.collection<INoteJSON>('notes', noteInitializer);
        this.blocks = this.budb.collection<IBlockJSON>('blocks', blockInitializer);
        this.folders = new FolderStore(this)

        makeAutoObservable(this);
    }

    public async asZip(): Promise<void> {
        let files: { [key: string]: Uint8Array } = {};

        this.notes.docs.forEach((note) => {
            files[`${note.data.title}.md`] = strToU8(this.note(note._id).asMarkdown);
        });

        const x = new Promise<Uint8Array>((resolve, reject) => {
            zip(files, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });

        const data = await x;
        const blob = new Blob([data], { type: "application/zip" });
        saveAs(blob, "notegarden.zip");
    }

    public get today(): NoteStore {
        const id = this.timeStore.todayISO;
        return this.note(id)
    }

    public note(id: string): NoteStore {
        if (!uniqsNote[id]) {
            const t = this.notes.get(id);
            const n = new NoteStore(this, t)
            uniqsNote[id] = n
        }
        return uniqsNote[id]
    }

    public block(id: string): BlockStore {
        if (!uniqsBlocks[id]) {
            const t = this.blocks.get(id);
            const n = new BlockStore(this, t)
            uniqsBlocks[id] = n
        }
        return uniqsBlocks[id]
    }

    public resolve(subject?: string): string | undefined {
        if (subject && subject.length > 0) {
            const url = `/note/${encodeURIComponent(subject)}`;
            return url;
        }

        return undefined;
    }

    public get conceptNames(): string[] {
        const subjects: Set<string> = new Set();

        for (const block of this.blocks.docs) {
            const b = this.block(block._id)
            const subject = b.doc.data.subject;

            if (subject) {
                subjects.add(subject);
            }

            for (const subject of b.references) {
                subjects.add(subject);
            }
        }

        for (const note of this.notes.docs) {
            subjects.add(note.data.title);
        }

        return sortBy([...subjects.values()]);
    }
}