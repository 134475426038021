import { Doc, withUniqueOptimism } from "@budb";
import { OptimisticDoc } from "@budb/OptimisticDoc";
import { Status } from "@budb/status";
import { isBlock } from "@c/GardenEditor/types";
import { makeLogger } from "@gazebo/utils";
import { NotebookStore } from "@store";
import every from "lodash/every";
import isEqual from "lodash/isEqual";
import { action, makeAutoObservable, reaction, when } from "mobx";
import { v4 as uuid } from "uuid";
import { BlockStore } from "./BlockStore";
import { INoteJSON, NoteContent, SlateContent } from "./types";

const logger = makeLogger("store:note:SlateNoteStore");
let happened = false

export class SlateNoteStore {
    public readonly doc: OptimisticDoc<INoteJSON>;
    public readonly notebook: NotebookStore;
    public status: Status;

    constructor(doc: Doc<INoteJSON>, notebook: NotebookStore) {
        this.doc = withUniqueOptimism(doc);
        this.notebook = notebook;
        this.status = Status.LOADING;

        reaction(() => this.isReady,
            (ready) => {
                if (ready) {
                    this.status = Status.READY;
                }
            })

        makeAutoObservable(this, {
        });
    }

    public get isReady(): boolean {
        return this.doc.status === Status.READY && (
            every(this.blocks, x => x.doc.status === Status.READY
            )
        )
    }

    public get blocksWithChanges(): BlockStore[] {
        return this.blocks.filter(x => x.optimism.hasChanges)
    }

    public get blocks(): BlockStore[] {
        const blocks = this.doc.data.content
            .map(x => {
                if (!x.blockID) {
                    return null;
                }
                const blockStore = this.notebook.block(x.blockID);
                return blockStore
            })
            .filter(x => !!x) as BlockStore[]

        logger.inTestEnv('blocks:', blocks)
        return blocks
    }

    public refresh() {
        this.blocks.forEach(block => block.optimism.refresh())
        this.doc.refresh()
    }

    public get content(): SlateContent {
        const r: SlateContent = this.doc.data.content.map((x) => {
            if (x.type === "blockReference") {
                const blockStore = this.notebook.block(x.blockID);
                return blockStore.slateContent;
            } else if (x.type === "block") {
                return x
            } else {
                throw new Error("unhandled");
            }
        });

        const result = r.slice()
        logger.inTestEnv('slate content (stores):', result)
        return result
    }

    public setContent(slate: SlateContent, changedBlocks: Set<number>) {
        logger.inTestEnv('update slate content:', slate, 'changed blocks:', changedBlocks)

        const blocks: NoteContent = []

        slate.forEach((block, i) => {
            if (!isBlock(block)) {
                throw new Error("invalid input: not a block");
            }

            const { blockID } = block;
            const id = blockID || uuid();

            const blockStore = this.notebook.block(id);

            if (changedBlocks.has(i)) {
                blockStore.slateContent = block;
            }

            blocks.push(blockStore.ref)
        });

        if (isEqual(this.doc.data_?.content, blocks)) {
            logger.inTestEnv('skipping update on identitical data')
            return;
        }

        this.doc.update(action((x: INoteJSON): INoteJSON => {
            return {
                ...x,
                content: blocks,
            };
        }));
    }
}
