import { Doc } from "./Doc";
import { OptimisticDoc } from "./OptimisticDoc";

let uniqs: { [key: string]: OptimisticDoc<any> } = {}

export const withOptimism = <T>(doc: Doc<T>, ...args: any[]): OptimisticDoc<T> => {
    return new OptimisticDoc(doc, ...args);
};

export const withUniqueOptimism = <T>(doc: Doc<T>, ...args: any[]): OptimisticDoc<T> => {
    const u = uniqs[doc._id]

    if (!u) {
        uniqs[doc._id] = withOptimism(doc, ...args)
    }

    return uniqs[doc._id] as OptimisticDoc<T>
};
