import { makeLogger } from "@gazebo/utils";
import omit from "lodash-es/omit";
import isEqual from "lodash/isEqual";
import {
    action, computed,
    makeAutoObservable, observable,
    reaction,
    runInAction,
    toJS
} from "mobx";
import { Doc, IDocJSON, IDocUpdater } from "./Doc";
import { Q } from "./Q";
import { Status } from "./status";

const logger = makeLogger("budb:OptimisticDoc");

export class UpdateStatus<T> {
    public rev: string | null;
    public id: string | null;
    public isComplete: boolean;

    constructor(id: string | null) {
        this.rev = null;
        this.id = id;
        this.isComplete = false

        makeAutoObservable(this, {});
    }

    public onUpdate({ rev, id }: { rev: string; id: string; }) {
        this.rev = rev;
        this.id = id
    }

    public onComplete() {
        this.isComplete = true
    }
}

type QUpdate<T> = { f: IDocUpdater<T>, status: UpdateStatus<T>, doc: IDocJSON<T> }

export class OptimisticDoc<T> {
    private readonly doc: Doc<T>;
    public data_: IDocJSON<T> | null;

    public hangingStatuses_: UpdateStatus<T>[]
    public updates_: Q<QUpdate<T>>;

    constructor(doc: Doc<T>, debounce: number | undefined = 1000) {
        this.doc = doc;
        this.data_ = doc.data;
        this.hangingStatuses_ = []

        this.updates_ = new Q(async ({ f, status, doc }) => {
            let firstCall = true

            const { rev, id } = await this.doc.update((x: IDocJSON<T>) => {
                if (firstCall) {
                    firstCall = false
                    return doc
                }
                else {
                    return f(x)
                }
            })

            runInAction(() => {
                status.onUpdate({ rev, id })
            })
        }, debounce, !!debounce)

        makeAutoObservable(this, {
            update: action,
            data: computed,
            data_: observable.ref,
            updates_: observable.ref,
            hangingStatuses_: observable.shallow,
        });

        reaction(() => this.doc.data,
            (data) => {
                const diffs = { [this.doc._id]: this.hangingStatuses_.map(x => x.rev).filter(rev => rev !== null) as string[] }

                this.doc._budb.db
                    .revsDiff(diffs)
                    .then(action(result => {
                        const missing = result[this.doc._id]?.missing || []

                        const rest: UpdateStatus<T>[] = []

                        this.hangingStatuses_.forEach(x => {
                            const rev = x.rev

                            if (rev === null || missing.includes(rev as string)) {
                                rest.push(x)
                            } else {
                                x.onComplete()
                            }
                        })

                        this.hangingStatuses_ = rest
                    }))

            })
    }

    public get status(): Status {
        return this.doc.status;
    }

    public get hasChanges(): boolean {
        // We consumed all updates:
        const noUpdatesWaiting = this.updates_.isEmpty && this.hangingStatuses_.length === 0
        // We don't know the current revision:
        const currentDataIsUnKnown = this.doc._data._rev !== this.data_?._rev

        // TODO: fix this, this is terrible
        const areEqual = isEqual(omit(toJS(this.doc._data), '_rev'), omit(toJS(this.data_), '_rev'))

        return currentDataIsUnKnown && noUpdatesWaiting && !areEqual
    }

    public refresh() {
        return this.data_ = this.doc.data
    }

    public get data(): IDocJSON<T> {
        logger.inTestEnv('get data(): ', 'has internal data:', this.data_ !== null, 'internal data:', this.data_, 'document data:', this.doc.data)
        return this.data_ !== null ? this.data_ : this.doc.data;
    }

    public update(f: IDocUpdater<T>): UpdateStatus<T> {
        const doc = this.doc._prepare_update(f);

        logger.inTestEnv('set local data:', doc)
        this.data_ = doc;

        const status = new UpdateStatus(doc._id);
        this.hangingStatuses_.push(status)
        this.updates_.push({ doc, status, f })

        return status
    }
}

