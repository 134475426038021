import { autorun, makeAutoObservable, observable, runInAction } from "mobx";
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';


export class Q<T> {
    public xs: T[];
    public currentlyProcessing: null | IPromiseBasedObservable<void>;
    public readonly f: (x: T) => Promise<void>;
    public readonly debounce?: number;
    public readonly onlyLast?: boolean;

    constructor(f: (x: T) => Promise<void>, debounce?: number, onlyLast?: boolean) {
        this.xs = [];
        this.currentlyProcessing = null;
        this.f = f;
        this.debounce = debounce
        this.onlyLast = onlyLast

        makeAutoObservable(this, {
            xs: observable.shallow,
            currentlyProcessing: observable.ref
        });

        autorun(() => {
            // skip on empty queue OR when we are processing something.
            if (this.xs.length === 0 || (!!this.currentlyProcessing && this.currentlyProcessing.state === 'pending')) {
                return;
            }

            const item = this.onlyLast ? this.xs[this.xs.length - 1] : this.xs[0];
            const removedItems = this.onlyLast ? this.xs.slice() : [item]
            const processing = fromPromise(this.f(item));

            runInAction(() => {
                this.currentlyProcessing = processing;
                if (this.onlyLast) {
                    // TOOD: stupid, make this efficient.
                    removedItems.forEach(() => this.xs.shift())
                } else {
                    this.xs.shift();
                }
            });
        }, { delay: this.debounce });
    }

    public get isEmpty(): boolean {
        return this.xs.length === 0 && (!this.currentlyProcessing || this.currentlyProcessing.state !== 'pending');
    }

    public push(x: T) {
        this.xs.push(x);
    }
}
