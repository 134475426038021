import { IDocInitializer } from "@budb/Doc";
import { baseBlock } from "@c/GardenEditor/blocks";
import { makeAutoObservable } from "mobx";
import { Descendant, Element } from "slate";
import { Doc } from "../budb";
import { BlockStore } from "./BlockStore";
import { NotebookStore } from "./NotebookStore";
import { SlateNoteStore } from "./SlateNoteStore";
import { INoteJSON } from "./types";

export const noteInitializer: IDocInitializer<INoteJSON> = (id: string) => {
  return {
    path: [],
    title: id,
    content: [baseBlock()],
  };
};


export class NoteStore {
  public readonly doc: Doc<INoteJSON>;
  public readonly notebook: NotebookStore;
  public readonly slate: SlateNoteStore;

  constructor(notebook: NotebookStore, doc: Doc<INoteJSON>) {
    this.doc = doc;
    this.notebook = notebook;
    this.slate = new SlateNoteStore(doc, notebook)

    makeAutoObservable(this);
  }

  public get id(): string {
    return this.doc._id;
  }

  public get data(): INoteJSON {
    return this.doc.data;
  }

  public get asMarkdown(): string {
    this.slate.refresh()

    const slate = this.slate.content;

    const recursiveDescent = (value: Descendant): string => {
      if (Element.isElement(value)) {
        switch (value.type) {
          case "block": {
            const r = value.children.map(recursiveDescent);
            return `\n${r.join("\n")}\n`;
          }
          case "internalLink": {
            const r = value.children.map(recursiveDescent);
            return r.join(" ");
          }
          case "paragraph": {
            const r = value.children.map(recursiveDescent);
            return r.join("") + "\n";
          }
          case "title": {
            const r = value.children.map(recursiveDescent);
            return r.join("") + '\n';
          }
        }
      } else {
        return `${value.text}`;
      }
    };

    let r = "";
    slate.forEach((value) => {
      r = `${r}${recursiveDescent(value)}`;
    });

    return r;
  }

  public get references(): string[] {
    const r = [];
    // TODO: fix typing
    let q: any[] = [this.data];

    do {
      const [first, ...rest] = q;
      q = [...rest];

      if (first.type === "internalLink") {
        r.push(first.target);
      }
      if (first.type === "title") {
        console.log(first);
        r.push(first.subject);
      }

      first.children?.forEach((x: any) => {
        q.push(x);
      });
    } while (q.length > 0);

    return r;
  }

  public get externalBlocks(): BlockStore[] {
    return [];
  }
}
