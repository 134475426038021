import { makeLogger } from "@gazebo/utils";
import { action } from "mobx";
import { Collection } from "./Collection";
import { Doc, IDocInitializer, IDocInitialJSON, isDocJSON } from "./Doc";
import { Status } from "./status";

const logger = makeLogger("budb:core");

export class BuDB {
  public status: Status;
  public readonly collections: Map<string, Collection<any>>;
  public readonly docs: Map<string, Doc<any>>;
  public readonly db: PouchDB.Database;

  public constructor(db: PouchDB.Database) {
    this.db = db;
    this.status = Status.NONE;
    this.collections = new Map();
    this.docs = new Map();

    this.db
      .createIndex({ index: { fields: ["type"] } })
      .then(action(() => (this.status = Status.LOADING)));

    this.db
      .changes({
        since: "now",
        live: true,
        include_docs: true,
      })
      // Note the wrapper here, this lets us spy the method during testing.
      .on("change", (x) => this.onDBChange(x))
      .on(
        "error",
        action((err) => {
          this.status = Status.ERROR;
          console.error(err);
        })
      );
  }

  public onDBChange(change: PouchDB.Core.ChangesResponseChange<any>) {
    if (change.deleted) {
      // TODO
    } else {
      const { doc } = change;

      if (!doc) {
        return;
      }

      // ignore design docs
      if (doc._id.startsWith("_design")) {
        return;
      }

      logger.inTestEnv("processing change:", change);

      // if (!isDocJSON(doc)) {
      //     console.error('we have a problem with this doc', doc)
      //     throw new Error(`we have a problem with this doc: ${doc._id}`)
      // }

      const localDoc = this.docs.get(doc._id);
      if (localDoc) {
        logger.inTestEnv("updating the local doc:", doc._id);
        localDoc.__loadFromBuDB(doc);
      }

      if (isDocJSON(doc) && doc.type) {
        // TODO: optimize collection updates by only worrying about changes of types, creation and deletion.
        // The rest won't change the content of a collection.
        const localCollection = this.collections.get(doc.type);

        if (localCollection) {
          logger.inTestEnv(
            "updating the collection:",
            doc.type,
            "after change on doc:",
            doc._id
          );
          localCollection.__loadFromBuDB(doc);
        }
      }
    }
  }

  public document<T>(id: string, initialData: IDocInitialJSON<T>): Doc<T> {
    const doc = this.docs.get(id);

    if (!doc) {
      const doc = new Doc<T>(this, id, initialData);
      this.docs.set(id, doc);
      return doc;
    }

    return doc as Doc<T>;
  }

  public collection<T>(
    type: string,
    initializer: IDocInitializer<T>
  ): Collection<T> {
    const coll = this.collections.get(type);

    if (!coll) {
      const coll = new Collection<T>(this, type, initializer);
      this.collections.set(type, coll);
      return coll;
    }

    return coll as Collection<T>;
  }
}
