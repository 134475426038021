import { Doc } from "@budb";
import { Status } from "@budb/status";
import { NotebookStore } from "@store";
import { timeStamp } from "console";
import { sortBy } from "lodash";
import { makeAutoObservable, observable, toJS } from "mobx";
import { v4 as uuid } from 'uuid';

interface IFolderJSON {
    type: 'folder'
    _id: string;
    name: string;
    children: string[];
}

export interface INoteLocationJSON {
    type: 'note';
    name: string;
    _id: string;
}

type ThingsInFolder = IFolderJSON | INoteLocationJSON

export interface IRecursiveFolderJSON {
    type: 'folder'
    _id: string;
    name: string;
    children: RecursiveThingsInFolder[];
    path: string[];
}

interface IFoldersJSON {
    type: 'folders',
    data: IFolderJSON[]
}

export type RecursiveThingsInFolder = (IRecursiveFolderJSON | INoteLocationJSON) & { path: string[] }

export class FolderStore {
    private readonly notebook: NotebookStore;
    public doc: Doc<IFoldersJSON>;
    // public sortedThings: ThingsInFolder[];

    constructor(notebook: NotebookStore) {
        this.notebook = notebook

        this.doc = notebook.budb.document<IFoldersJSON>('garden_folders', {
            type: 'folders',
            data: []
        })

        makeAutoObservable(this, {
            // folders: observable.shallow,
            // sortedThings: observable.shallow
        })
    }

    public get folders(): IFolderJSON[] {
        return this.doc.data.data
    }

    public create() {
        this.doc.update((x: IFoldersJSON) => {
            const newItem: IFolderJSON = { type: 'folder', _id: uuid(), name: 'New Folder', children: [] }

            return {
                ...x,
                data: [...x.data, newItem]
            }
        })
    }

    public remove(_id: string) {
        this.doc.update((x: IFoldersJSON) => {
            return {
                ...x,
                data: x.data.filter(x => x._id === _id)
            }
        })
    }

    public rename(_id: string, name: string) {
        this.doc.update((x: IFoldersJSON) => {
            return {
                ...x,
                data: x.data.map(x => x._id === _id ? ({ ...x, name }) : x)
            }
        })
    }

    public parent(_id: string): IFolderJSON | undefined {
        return this.doc.data.data.find(thing => thing.children.find(id => id === _id))
    }

    public move(_id: string, destinationId?: string) {
        this.doc.update((x: IFoldersJSON) => {
            const parent = x.data.find(thing => thing.children.find(id => id === _id))

            let nextData = x.data

            if (parent) {
                nextData = nextData.map(f => {
                    if (f._id === parent._id) {
                        return { ...f, children: f.children.filter(id => id !== _id) }
                    }
                    return f
                })
            }

            if (destinationId) {
                nextData = nextData.map(f => {
                    if (f._id === destinationId) {
                        return { ...f, children: [...f.children, _id] }
                    }
                    return f
                })
            }

            return { ...x, data: nextData }
        })
    }

    public get everythings(): ThingsInFolder[] {
        const r1: ThingsInFolder[] = [...this.folders]
        const r2: ThingsInFolder[] = this.notebook.notes.docs.map(x => ({ _id: x._id, type: 'note', name: x.data.title }))

        const r = [...r1, ...r2]

        return sortBy(r, 'name', '_id')
    }

    public get roots(): ThingsInFolder[] {
        return this.everythings
            .filter(x => !this.parent(x._id))
    }

    public thingsIn(_id: string, path: string[] = []): RecursiveThingsInFolder {
        const thing = this.everythings.find(x => x._id === _id)

        if (!thing) {
            throw new Error(`invalid request, thingsIn: "${_id}" returned nothing`)
        }

        if (thing.type === 'note') {
            return { ...thing, path }
        }
        if (thing.type === 'folder') {
            return {
                ...thing,
                path,
                children: thing.children.map(c => this.thingsIn(c, [...path, thing._id]))
            }
        }

        throw new Error('missing implem')
    }

    public get hierarchy(): RecursiveThingsInFolder[] {
        try {
            return this.roots.map(x => this.thingsIn(x._id))
        } catch (e) {
            return [] // TODO: this is terrible.
        }
    }
}