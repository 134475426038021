import {
  collection as firestoreCollection,
  doc as firestoreDoc,
  getDoc as getFirestoreDoc,
  onSnapshot,
  setDoc as setFirestoreDoc,
  Unsubscribe
} from "@firebase/firestore";
import { firestore } from "@gazebo/firebase";
import FirestoreProfileStore from "@gazebo/mobx/FirestoreProfileStore";
import { isServerSide } from "@gazebo/nextjs/utils";
import { makeLogger } from "@gazebo/utils";
import omit from "lodash-es/omit";
import { action, autorun } from "mobx";
import { BuDB } from "./BuDB";
import { IDocJSON, isDocJSON, isMoreRecent } from "./Doc";
import { isPouchError } from "./pouch";
import { Status } from "./status";
import { isLegacyDocJSON } from "./utils";

const logger = makeLogger("budb:firestore");

export const withFirestore = (buDB: BuDB, profile: FirestoreProfileStore) => {
  let changes: { cancel: () => void } | null = null;
  let unsub: Unsubscribe | null = null;

  if (isServerSide()) {
    logger.inTestEnv("skipping server side");
    return buDB;
  }

  logger.inTestEnv("Setting up firestore sync...");

  const lastSeq = parseInt(localStorage.getItem("last-synced-seq") || "0");
  logger.inTestEnv("starting firestore sync from:", lastSeq);

  // local => remote
  autorun(() => {
    const userID = profile.userID;

    if (!userID) {
      logger.inTestEnv("no user id, not syncing");
      changes && changes.cancel();
      changes = null;
      return;
    }

    // TODO: make sure the user id is the correct one, we don't want to merge databases between users on the same computer.
    logger.inTestEnv("We have a user id, starting to sync local => remote");

    // TODO: implement a queue of changes indexed by document id, to prevent race conditions on multiple changes.
    changes = buDB.db
      .changes({
        since: lastSeq,
        live: true,
        include_docs: true,
      })
      .on(
        "change",
        action(async (change) => {
          logger.inTestEnv("processing change:", change);

          if (change.deleted) {
            // TODO: Handle deletes
            logger.inTestEnv("skipping deletion");
          } else {
            const { doc } = change;

            if (!doc) {
              return;
            }

            if (!isDocJSON(doc)) {
              if (doc._id.startsWith("_design")) {
                logger.inTestEnv("skipping design doc:", doc._id);
                return;
              }

              logger.toInvestigateTomorrow(
                "we have a problem with this doc",
                doc
              );
              throw new Error(`we have a problem with this doc: ${doc._id}`);
            }

            const d = firestoreDoc(
              firestore,
              `/users/${userID}/budb/${doc._id}`
            );

            const got = await getFirestoreDoc(d);

            let storeToFirestore = false;

            if (!got.exists()) {
              logger.inTestEnv(
                "The doc doesn't exists remotely, we're going to create it"
              );
              storeToFirestore = true;
            } else {
              const remoteDoc = got.data();

              logger.inTestEnv(
                "comparing remote:",
                remoteDoc,
                "and local:",
                doc
              );

              if (
                !remoteDoc ||
                (!isDocJSON(remoteDoc) &&
                  !isLegacyDocJSON(remoteDoc))
              ) {
                logger.toInvestigateTomorrow(
                  "we have a problem with this remoteDoc doc",
                  remoteDoc
                );
                throw new Error(
                  `we have a problem with this remoteDoc doc: ${doc._id}`
                );
              }

              if (isMoreRecent(doc, remoteDoc)) {
                logger.inTestEnv(
                  "The local doc is more recent than remote, we're going to update the remote"
                );
                storeToFirestore = true;
              }
            }

            if (storeToFirestore) {
              logger.inTestEnv("updating firestore:", doc);
              await setFirestoreDoc(d, omit(doc, '_rev'));
            } else {
              logger.inTestEnv("NOT updating firestore:", doc);
            }

            logger.inTestEnv("done with seq:", change.seq.toString());
            localStorage.setItem("last-synced-seq", change.seq.toString());
          }
        })
      )
      .on(
        "error",
        action((err) => {
          buDB.status = Status.ERROR;
          console.error(err);
        })
      );
  });

  // remote => local
  autorun(() => {
    const userID = profile.userID;

    // pass
    if (!userID) {
      logger.inTestEnv("no user id, not syncing");
      unsub && unsub();
      unsub = null;
      return;
    }

    logger.inTestEnv("We have a user id, starting to sync remote => local");

    // TODO: figure out a way to subscribe to documents too.
    const ref = firestoreCollection(firestore, `/users/${userID}/budb`);

    unsub = onSnapshot(ref, (x) => {
      const logger1 = logger.makeLogger('onSnapshot')

      x.docChanges().forEach(async (change) => {
        const remoteDoc = change.doc.data();

        const logger2 = logger1.makeLogger(`${change.doc.id}`)

        logger2.inTestEnv("Processing remote update:", remoteDoc);

        if (!remoteDoc || !isDocJSON(remoteDoc)) {
          logger2.toInvestigateTomorrow(
            "we have a problem with this remoteDoc doc",
            remoteDoc
          );
          throw new Error(
            `we have a problem with this remoteDoc doc: ${change.doc.id}`
          );
        }

        let localDoc = null;
        let skip = false

        try {
          localDoc = await buDB.db.get<IDocJSON<unknown>>(change.doc.id);
        } catch (error) {
          if (isPouchError(error)) {
            if (error.status === 404) {
              localDoc = null
              skip = true
            }
          }
          if (!skip) {
            throw error
          }
        }

        // logger.inTestEnv("comparing remote:", remoteDoc, "and local:", localDoc);

        if (!localDoc || isMoreRecent(remoteDoc, localDoc)) {
          logger2.inTestEnv(
            "The remote doc is more recent than local, we're going to update the local",
          );

          try {
            await buDB.db.put({
              ...remoteDoc,
              _rev: localDoc ? localDoc._rev : undefined,
            });
          } catch (error) {
            logger2.wakeMeUpInTheMiddleOfTheNight('the sync failed with error:', error,
              'remote:', remoteDoc, 'local:', localDoc
            )
            throw error
          }
        }
      });
    });
  });

  return buDB;
};
