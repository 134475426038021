import { BuDB } from "@budb";
import { makeLogger, sleep } from "@gazebo/utils";
import every from "lodash/every";
import max from "lodash/max";
import { action, computed, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import { withPouchRetries } from "./pouch";
import { Status } from "./status";
import { dateOrNull, ILegacyDoc } from "./utils";

const logger = makeLogger("budb:doc");

export interface IBaseDoc {
    _id: string;
    _rev?: string;
    type?: string;
    createdAt: number;
    deletedAt?: number;
    updatedAt?: number;
}

export type IDocJSON<T> = IBaseDoc & T;
export type IDocInitialJSON<T> = Partial<IBaseDoc> & T;
export type IDocInitializer<T> = (id: string) => IDocInitialJSON<T>;
export type IDocUpdater<T> = ((x: IDocJSON<T>) => T) | ((x: T) => T);

export type BuDBUpdate<T> = PouchDB.Core.ChangesMeta &
    PouchDB.Core.IdMeta &
    PouchDB.Core.RevisionIdMeta &
    IDocJSON<T>;

export const isDocJSON = (doc: any): doc is IDocJSON<unknown> => {
    const fields = ["_id", "createdAt"];
    return every(fields.map((field) => !!doc[field]));
};

export const isMoreRecent = (
    docA: IDocJSON<unknown>,
    docB: IDocJSON<unknown> | ILegacyDoc
): boolean => {
    const fieldA = max([docA.updatedAt, docA.deletedAt, docA.createdAt]);
    const fieldB = max([docB.updatedAt, docB.deletedAt, docB.createdAt]);
    return (fieldA || 0) > (fieldB || 0);
};

export class Doc<T> {
    public readonly _budb: BuDB;
    public readonly _id: string;
    public status: Status;
    public isFresh: boolean;

    public _data: IDocJSON<T>;

    public constructor(
        budb: BuDB,
        id: string,
        initialData: T & Partial<IDocJSON<T>>
    ) {
        this._budb = budb;
        this._id = id;

        this.status = Status.NONE;
        this.isFresh = true;

        this._data = {
            _id: this._id,
            createdAt: Date.now(),
            ...initialData,
        };

        this.status = Status.LOADING;

        this._budb.db
            .get<IDocJSON<T>>(this._id)
            .then((doc) => this.__loadFromBuDB(doc))
            .catch(
                action((err) => {
                    if (err.status === 404) {
                        // that's OK.
                        this.status = Status.READY;
                        return;
                    }
                    console.error("getting doc:", this._id, "got:", err);
                })
            );

        makeAutoObservable(this, {
            status: observable,
            isFresh: observable,
            _data: observable.ref,
            data: computed,
            createdAt: computed,
            updatedAt: computed,
            deletedAt: computed,
            __loadFromBuDB: action,
            _put: action,
            update: action,
        });
    }

    public get data(): IDocJSON<T> {
        return this._data;
    }

    public get createdAt(): Date | null {
        return dateOrNull(this._data.createdAt);
    }

    public get updatedAt(): Date | null {
        return dateOrNull(this._data.updatedAt);
    }

    public get deletedAt(): Date | null {
        return dateOrNull(this._data.deletedAt);
    }

    public async __loadFromBuDB(doc: IDocJSON<T>) {
        // logger.inTestEnv('loading from budb:', doc)
        this._data = doc;
        this.status = Status.READY;
        this.isFresh = false;
    }

    public _put(doc: IDocJSON<T>): Promise<{ rev: string; id: string }> {
        return this._budb.db.put<IDocJSON<T>>(toJS(doc));
    }

    public _prepare_update(f: IDocUpdater<T>): IDocJSON<T> {
        const isCreate = this.isFresh;
        const meta = isCreate
            ? { createdAt: Date.now() }
            : { createdAt: this._data.createdAt, updatedAt: Date.now() };

        // TODO: use deep clone
        const newData = f({ ...this._data });

        const doc: IDocJSON<T> = {
            ...this._data,
            ...newData,
            ...meta,
            _id: this._id,
        };

        return doc;
    }

    public async update(f: IDocUpdater<T>): Promise<{ rev: string; id: string }> {
        return withPouchRetries(
            action(() => {
                const doc = this._prepare_update(f);
                logger.inTestEnv("attempt to put update:", doc);
                return this._put(toJS(doc));
            })
        );
    }
}
